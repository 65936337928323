<template>
    <div class="creationCompetitionThree">
        <div class="grading_type">
            <div class="grading_type_title">评分类型：</div>
            <div class="grading_type_radio">
                <el-radio-group v-model="gradingTypeRadio">
                    <el-radio label='0'>将学生平均分配给评委，最终分数为该评委评分的分数</el-radio>
                    <el-radio label='1'>选择评分评委同时对学生评分，取平均分做该学生的分数</el-radio>
                    <el-radio label='2'>选择评分评委同时对学生评分，去掉最高分和最低分，剩下分数的平均分做该学生的分数</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="creationCompetitionThree-header">
            <div class="selectJury">
                <div class="selectJuryHeader">
                    选择竞赛评委
                </div>
                <div class="selectJuryContent">
                    <div class="selectJuryContentSpan" v-for="(item,index) in judgeList" :key="index">
                        <el-tag :class="selectJudgeList.includes(item) ? 'selectRed' : ''" @click="juryAdd(item)">{{item.name}}</el-tag>
                    </div>
                </div>
                <el-pagination
                        class="pages-center"
                        :current-page="adminPages.currentPageNum"
                        :page-size="adminPages.eachPageNum"
                        layout="prev, pager, next, jumper"
                        :total="adminPages.total"
                        @current-change="adminCurrentChange"
                ></el-pagination>
            </div>
            <div class="selectedJury">
                <div class="selectedJuryHeader">
                    已选择{{selectJudgeList.length}}个评委
                </div>
                <div class="selectedJuryContent">
<!--                    <span v-for="(item,index) in selectJudgeList" :key="index">{{item.name}}<i class="el-icon-close" @click="cancelSlect(index)"></i></span>-->
                    <div class="selectedJuryContentSpan" v-for="(item,index) in selectJudgeList" :key="index">
                        <el-tag closable @close="cancelSlect(index)" >{{item.name}}</el-tag>
                    </div>
                </div>
            </div>
        </div>
        <div class="upDownBtn">
            <el-button @click="toBack">上一步</el-button>
            <el-button style="background: #1122D8;border-color:#1122D8;color: #ffffff;margin-left: 20px" @click="toSucceed">下一步</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "creationCompetitionThree",
        data(){
            return {
                judgeList:[],
                selectJudgeList:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 20,
                    total: 0
                },
                gradingTypeRadio: '0'
            }
        },
        created() {
            this.getJudgesList();
        },
        methods: {
            getEditData(){
              if(this.$route.query.editForm){
                  let editForm = JSON.parse(this.$route.query.editForm);
                  let tIds = editForm.t_ids.split('#').map(Number);
                  this.gradingTypeRadio = editForm.score_type + ''
                  for(let i = 0; i < this.judgeList.length; i++){
                      for(let j = 0; j < tIds.length; j++){
                          if(this.judgeList[i].id === tIds[j]){
                              this.selectJudgeList.push(this.judgeList[i])
                          }
                      }
                  }
              }
            },
            getJudgesList(){
                let param = {
                    page:this.adminPages.currentPageNum,
                    paging:1,
                    pageSize:this.adminPages.eachPageNum
                };
                this.$http.axiosGetBy(this.$api.judges, param, res=>{
                    if(res.code === 200){
                        this.judgeList = res.data.data;
                        this.adminPages.total = res.data.total;
                        this.getEditData();
                    }
                })
            },
            juryAdd(item){
                if(this.selectJudgeList.length){
                    for(let i =0; i < this.selectJudgeList.length; i++){
                        if(this.selectJudgeList[i].id === item.id){
                            this.$message('已经选择过该评委！');
                            return;
                        }
                    }
                    this.selectJudgeList.push(item)
                } else {
                    this.selectJudgeList.push(item)
                }
            },
            cancelSlect(index){
                this.selectJudgeList.splice(index,1)
            },
            toBack(){
                this.$router.go(-1);
            },
            toSucceed(){
                let str = '';
                if(this.selectJudgeList.length < 1){
                    this.$message.warning('请选择评委！')
                    return;
                } else if(this.gradingTypeRadio === '') {
                    this.$message.warning('请选择评分类型！')
                    return;
                } else {
                    if(this.selectJudgeList.length === 1){
                        str += this.selectJudgeList[0].id;
                    } else {
                        str = this.selectJudgeList[0].id;
                        let separator = '#';
                        for(let i = 1; i < this.selectJudgeList.length; i++){
                            str += separator + this.selectJudgeList[i].id
                        }
                    }
                }
                let resTwo = JSON.parse(this.$route.query.resTwo);
                let tmp_id = this.$route.query.tmp_id;
                let begin_time = this.dateFormatTwo(resTwo.startTime);
                let end_time = this.dateFormatTwo(resTwo.overTime);
                let score_type = this.gradingTypeRadio
                let formData = new FormData();
                formData.append('name',resTwo.name);
                formData.append('introduce',resTwo.introduce);
                formData.append('category_id',resTwo.industryClassify);
                formData.append('team_id',resTwo.teamClassify);
                formData.append('t_ids',str);
                formData.append('begin_time',begin_time);
                formData.append('end_time',end_time);
                formData.append('tmp_id',tmp_id);
                formData.append('score_type', score_type);
                if(this.$route.query.editRes){
                    formData.append('com_id',this.$route.query.editRes)
                }
                this.$http.axiosPost(this.$api.saveCom, formData, res=>{
                    if(res.code === 200){
                        this.$router.push({
                            path:'/schooladmin/competition/creationCompetitionsucceed'
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getJudgesList()
            },
            dateFormatTwo:function(time) {
                var date=new Date(time);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #606266;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
        background: #1122D8 !important;
        border-color: #1122D8 !important;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 20px;
    }
    .creationCompetitionThree{
        .grading_type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            .grading_type_title {
                line-height: 18px;
                width: 80px;
                font-size: 16px;
            }
            .grading_type_radio {
                width: 80%;
                /deep/ .el-radio {
                    margin-bottom: 10px;
                }
            }
        }
        display: flex;
        flex-direction: column;
        padding:40px 0 30px 30px;
        .creationCompetitionThree-header{
            display: flex;
            .selectJury{
                min-height: 470px;
                width: 940px;
                border: #EEEEEE 1px solid;
                .selectJuryHeader{
                    height: 60px;
                    background: #F2F3FA;
                    line-height: 60px;
                    padding-left: 30px;
                    border-bottom: 1px solid #EEEEEE;
                    color: #333333;
                }
                .selectJuryContent{
                    /*padding:0 0px 30px 30px;*/
                    padding: 0 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    &:after {
                        content: "";
                        flex: auto;
                    }
                    .selectJuryContentSpan:nth-child(1){
                        margin-left: 0;
                    }
                    .selectJuryContentSpan{
                        margin-left: 20px;
                        /*justify-content: space-around;*/
                        text-align: center;
                        .selectRed{
                            color:#409EFF;
                            border-color: #409EFF;
                            background: #ffffff;
                        }
                    }
                    span{
                        /*margin-left:60px;*/
                        display: inline-block;
                        /*border: 1px solid #DCDCDC;*/
                        /*line-height: 38px;*/
                        /*padding: 0 7px;*/
                        background: #ffffff;
                        margin-top: 30px;
                        cursor:pointer;
                        color: #4B5366;
                        border-color: #E9EAEC;
                    }
                }
            }
            .selectedJury{
                margin-left: 30px;
                width: 588px;
                border: #EEEEEE 1px solid;
                .selectedJuryHeader{
                    height: 60px;
                    background: #F2F3FA;
                    line-height: 60px;
                    padding-left: 30px;
                    border-bottom: 1px solid #EEEEEE;
                    color: #333333;
                }
                .selectedJuryContent{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    padding: 0 20px;
                    &:after {
                        content: "";
                        flex: auto;
                    }
                    .selectedJuryContentSpan:nth-child(1){}
                    margin-left: 0;
                    .selectedJuryContentSpan{
                        margin-left: 20px;
                        text-align: center;
                    }
                    span{
                        display: inline-block;
                        margin-top: 30px;
                        cursor:pointer;
                        background: #ffffff;
                        border-color: #409EFF;
                    }
                }
            }
        }
        .upDownBtn{
            margin: 0 auto;
            padding-top: 40px;
        }
    }
</style>